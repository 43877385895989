import React from "react";
import Layout from "../components/layout"
import Stripe from "../components/stripe"
import Background from "../components/background"
import styled from "styled-components"
import style from "../styles/main"
import Media from 'react-media'
import PageMetadata from "../components/SEO"

const Line = styled.hr`
  width: 20%;
  border: 1px solid ${style.buttonColor};
  text-align: left;
  margin-left: 0px;
`;

const Contact = styled.div`
  font-family: rewirFont;
  font-size: 1.25em;
  text-align: justify;
  text-align-last: left;
  @media not all and (min-width: ${style.mediaMinWidth}px) {
    width: calc(100% - 56px);
    margin: auto;
    position: relative;
    top: 50vh;
    transform: translateY(-50%);
  }
  @media all and (min-width: ${style.mediaMinWidth}px) {
    position: absolute;
    left: 15%;
    width: 20%;
    top: 50%;
    margin: auto;
    transform: translateY(-50%);
  }
  a {
    text-decoration: none;
    color: ${style.textColor};
  }
  a:hover {
    text-decoration: underline;
  }
`

export default () => {
  return (
    <>
      <PageMetadata />
      <Layout initialHeight={100}>
        <Contact>
          <div>Get in touch!</div>
          <Line align="left"/>
          <p>Karolina Kulis</p>
          <p style={{fontWeight: "bold"}}><a href="mailto:studio@karolinakulis.com">studio@karolinakulis.com</a><br/>
            +48 512 140 961</p>
        </Contact>
        <Media query={`(min-width: ${style.mediaMinWidth}px)`}>
          <Background>
            <Stripe m={30} l={64} w={35} h={65}/>
          </Background>
        </Media>
      </Layout>
    </>
  )
}
